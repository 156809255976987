/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url(./assets/css/theme.css);
.modal-lg {
  max-width: 95% !important;
  width: 95% !important;
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: relative !important;
  left: 0px !important;
  opacity: 1 !important;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: relative !important;
  left: 0px !important;
  opacity: 1 !important;
}

.clickable {
  cursor: pointer;
}

.fc-white {
  color: #fff;
}

.bgc-dark {
  background-color: #343a40;
  border-color: #454d55;
}

.grid-list-dark {
  background-color: #343a40;
  border-color: #454d55;
  color: #fff;
}

.grid-list-light {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.bgc-light {
  background-color: #e7e7e7;
  border-color: #e7e7e7;
}
.disable_ripple:hover {
  background: inherit !important;
}

.btn .btn-grey {
  background-color: #8f8d8d;
}

.btn .btn-grey:hover {
  background-color: #d1cdcd;
}
.btn {
  margin-right: 3px;
  margin-left: 3px;
}
.mat-simple-snackbar-action {
  color: #fff;
}
.snack-bar-server-danger {
  background-color: rgb(194, 59, 59);
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.snack-bar-server-success {
  background-color: rgb(16, 104, 16);
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.snack-bar-server-warn {
  background-color: rgb(168, 130, 5);
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.snack-bar-server-info {
  background-color: rgb(61, 80, 252);
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.mat-grid-right {
  width: 100%;
  text-align: right;
  padding-right: 1rem;
}
.mat-grid-left {
  width: 100%;
  text-align: left;
  padding-left: 1rem;
}
.fc-green {
  color: #00c292;
}
.correction-name-cell:hover {
  background-color: #d4d1d1;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.tab-content {
  overflow: hidden;
  margin-left: 1rem;
  margin-top: 1rem;
}
.tab-content-heading {
  float: left;
  line-height: 30px;
  font-size: 21px;
  font-weight: bold;
}
