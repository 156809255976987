.text-green {
  color: #016f2b;
}

.bg-green {
  background: #016f2b;
}

.text-light-green {
  color: #9cc123;
}

.bg-light-green {
  background: #9cc123;
}

.text-yellow {
  color: #f5ed20;
}

.bg-yellow {
  background: #f5ed20;
}

.text-orange {
  color: #f08e10;
}

.bg-orange {
  background: #f08e10;
}

.text-red {
  color: #d73137;
}

.bg-red {
  background: #d73137;
}

.text-blue {
  color: #1e549e;
}

.bg-blue {
  background: #1e549e;
}

.text-white {
  color: #fff;
}
